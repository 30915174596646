import React from "react";
import chatIcon from "../assets/header/chat.svg";
import { useTranslation } from 'react-i18next';
import { CLIENT_PORTAL } from "../constants";
export const StartNow = (props) => {
  const { t } = useTranslation();
  return (
    <div id="startNow">
      <div className="inner">
        <div className="ic">
          <img src={chatIcon} alt="" />

            <div className="txtinput">
            <div className="title forPC">
                <span> {t(`start_now_title`)}</span>
            </div>

            </div>
        </div>
        
        <div className="btn" onClick={()=>{
            window.location.href = CLIENT_PORTAL;
        }}>
          <span> {t(`start_now_btn`)}</span>
        </div>
      </div>
    </div>
  );
};
