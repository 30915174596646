import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import usIcon from "../assets/header/us_flag.png";
import jpIcon from "../assets/header/ja_flag.png";
import { CLIENT_PORTAL } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGlobe,
  faHamburger,
} from "@fortawesome/free-solid-svg-icons";
export const Navigation = (props) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    768 < window.innerWidth && window.innerWidth <= 1200
  );
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const handleScrollFormOtherPage = (component) => {
    const compo = document.getElementById(component);
    if (!compo) {
      setTimeout(() => {
        const compo = document.getElementById(component);
        if (compo) {
          compo.scrollIntoView({ behavior: "smooth" });
        }
      }, 1500);
    }
  };
  useEffect(() => {
    const navbar = document.querySelector(".navbar-fixed-top");

    const handleScroll = () => {
      let st = window.scrollY || document.documentElement.scrollTop;
      if (isMobile) {
        const navbarCollapse = document.querySelector(".navbar-collapse");
        if (lastScrollTop >= st) {
          navbar.classList.remove("navbar-hidden");
        } else {
          navbar.classList.add("navbar-hidden");
          navbarCollapse.classList.remove("in");
        }
      } else {
        if (st > 100) {
          navbar.classList.add("navbar-fixed");
        } else {
          navbar.classList.remove("navbar-fixed");
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(768 < window.innerWidth && window.innerWidth <= 1200);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollTop, isMobile, isTablet]);
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="./#page-top">
            <img className="logo" src={i18n.language === 'ja'?"img/logo-ja.png":"img/logo.png"} alt="logo" />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="./#header"
                className="page-scroll"
                onClick={() => handleScrollFormOtherPage("header")}
              >
                {t("home")}
              </a>
            </li>

            <li>
              <a
                href="./#services"
                className="page-scroll"
                onClick={() => handleScrollFormOtherPage("services")}
              >
                {t("services")}
              </a>
            </li>
            <li>
              <a
                href="./#pricing"
                className="page-scroll"
                onClick={() => handleScrollFormOtherPage("pricing")}
              >
                {t("pricing")}
              </a>
            </li>
            <li>
              <a
                href="./#about"
                className="page-scroll"
                onClick={() => handleScrollFormOtherPage("about")}
              >
                {t("about_us")}
              </a>
            </li>
            <li>
              <a href="./#contact" className="page-scroll">
                {t("contact")}
              </a>
            </li>
            {!isTablet && (
              <li>
                <button
                  className="btn btn-primary btn-login"
                  onClick={() => {
                    window.location.href = CLIENT_PORTAL + "/auth/login";
                  }}
                  type="button"
                >
                  {t("login")}
                </button>
              </li>
            )}
            {!isTablet && (
              <li>
                <button
                  className="btn btn-primary btn-sign-up"
                  onClick={() => {
                    window.location.href = CLIENT_PORTAL + "/auth/registration";
                  }}
                  type="button"
                >
                  {t("sign_up")}
                </button>
              </li>
            )}
            {!isTablet &&
              (isMobile ? (
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      id="dropdownMenuButton"
                      type="button"
                      onClick={() =>
                        changeLanguage(i18n.language === "en" ? "ja" : "en")
                      }
                    >
                      <img
                        src={i18n.language === "en" ? jpIcon : usIcon}
                        alt="language"
                      />
                      <p>{i18n.language === "en" ? "日本語" : "English"}</p>
                    </button>
                  </div>
                </li>
              ) : (
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon
                        size={"2x"}
                        color="#3778f7"
                        icon={faGlobe}
                      />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        className="dropdown-item"
                        onClick={() => changeLanguage("en")}
                      >
                        <img src={usIcon} alt="language" /> English
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => changeLanguage("ja")}
                      >
                        <img src={jpIcon} alt="language" /> 日本語
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            {isTablet && (
              <li>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon
                      size={"2x"}
                      color="#3778f7"
                      icon={faBars}
                    />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => changeLanguage("ja")}
                    >
                      <button
                        className="btn btn-primary btn-login"
                        onClick={() => {
                          window.location.href = CLIENT_PORTAL + "/auth/login";
                        }}
                        type="button"
                      >
                        {t("login")}
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <button
                        className="btn btn-primary btn-sign-up"
                        onClick={() => {
                          window.location.href =
                            CLIENT_PORTAL + "/auth/registration";
                        }}
                        type="button"
                      >
                        {t("sign_up")}
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        id="dropdownMenuButton"
                        type="button"
                        onClick={() =>
                          changeLanguage(i18n.language === "en" ? "ja" : "en")
                        }
                      >
                        <img
                          src={i18n.language === "en" ? jpIcon : usIcon}
                          alt="language"
                        />
                        <p>{i18n.language === "en" ? "日本語" : "English"}</p>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
