/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import example1 from "../assets/tryPhoto/01-before.jpg";
import example2 from "../assets/tryPhoto/02-before.jpg";
import example3 from "../assets/tryPhoto/03-before.jpg";
import example4 from "../assets/tryPhoto/04-before.jpg";
import output1 from "../assets/tryPhoto/01-after.jpeg";
import output2 from "../assets/tryPhoto/02-after.jpeg";
import output3 from "../assets/tryPhoto/03-after.jpeg";
import output4 from "../assets/tryPhoto/04-after.jpeg";
import folderIco from "../assets/tryPhoto/ico_folder.png";
import exampleBefore from "../assets/tryPhoto/example_before.jpeg";
import exampleAfter from "../assets/tryPhoto/example_after.jpeg";
import Dropzone from "react-dropzone";
import axios from "axios";
import {
  BACKEND_URL,
  PHOTO_ENHANCE_SERVICE,
  PHOTO_MAX_SIZE,
  PHOTO_MIN_SIZE,
  PHOTO_MAX_TIME_TRY,
} from "../constants";
import { toast } from "react-toastify";
import ReactCompareImage from "react-compare-image";
const exampleData = [
  {
    input: example1,
    output: output1,
  },
  {
    input: example2,
    output: output2,
  },
  {
    input: example4,
    output: output4,
  },
  {
    input: example3,
    output: output3,
  },
];
export const TryPhoto = (props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("upload");
  const [beforeImg, setBeforeImg] = useState(exampleBefore);
  const [afterImg, setAfterImg] = useState(exampleAfter);
  const dropzoneRef = React.useRef();

  const processPhoto = (file) => {
    if (getCurrentTries() > PHOTO_MAX_TIME_TRY) {
      return;
    }
    setMode("loading");
    setBeforeImg(URL.createObjectURL(file));
    setAfterImg(URL.createObjectURL(file));

    const formData = new FormData();

    formData.append("file", file);
    formData.append("serviceId", PHOTO_ENHANCE_SERVICE);
    axios
      .post(BACKEND_URL + "/api/v1/public/trials", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAfterImg(response.data.result);
          setMode("download");
          incrementTries();
        } else {
          toast.error(t("try_photo_error"));
          setMode("upload");
        }
        // assign image to the target element
      })
      .catch((error) => {
        toast.error(t("try_photo_error"));
        setMode("upload");
      });
  };
  const processExamplePhoto = (item) => {
    if (getCurrentTries() > PHOTO_MAX_TIME_TRY) {
      return;
    }
    setMode("loading");
    setBeforeImg(item.input);
    setAfterImg(item.output);
    const randomTimeout = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
    setTimeout(() => {
      setAfterImg(item.output);
      setMode("download");
      incrementTries();
    }, randomTimeout);
  };
  const renderAction = () => {
    if (mode === "upload") {
      return (
        <span onClick={() => handleActionClick("upload")}>
          {t(`try_photo_try_btn`)}{" "}
          <img src="./css/nivo-lightbox/upload_icon.svg" alt="" />
        </span>
      );
    } else if (mode === "loading") {
      return (
        <span>
          {t(`try_photo_loading_btn`)}
          <img src="./css/nivo-lightbox/loading.gif" alt="" />
        </span>
      );
    } else if (mode === "download") {
      return (
        <>
          {getCurrentTries() < PHOTO_MAX_TIME_TRY && (
            <span onClick={() => handleActionClick("upload")}>
              {t(`try_photo_try_again_btn`)}(
              {PHOTO_MAX_TIME_TRY - getCurrentTries()})
              <img src="./css/nivo-lightbox/retry-icon.svg" alt="" />
            </span>
          )}
          <button
            className="btn btn-secondary"
            onClick={() => handleActionClick("download")}
          >
            {t(`try_photo_download_btn`)}
            <img src="./css/nivo-lightbox/download-icon.svg" alt="" />
          </button>
        </>
      );
    }
  };
  const handleActionClick = (action) => {
    const currentTries = getCurrentTries();
    if (currentTries >= PHOTO_MAX_TIME_TRY) {
      toast.error(t("try_photo_mes_max_try"));
      return;
    }
    if (action === "upload") {
      //add dropzone click event
      dropzoneRef.current.open();
    }
    if (action === "download") {
      //download after image
      const link = document.createElement("a");
      link.href = afterImg;
      link.download = "enhanced.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setMode("upload");
    }
  };
  const getCurrentTries = () => {
    const triesData = JSON.parse(localStorage.getItem("triesData")) || {
      date: null,
      tries: 0,
    };
    const currentDate = new Date().toISOString().split("T")[0];

    if (triesData.date === currentDate) {
      return triesData.tries;
    } else {
      // Reset the tries if the current date is not the same as the date in local storage
      localStorage.setItem(
        "triesData",
        JSON.stringify({ date: currentDate, tries: 0 })
      );
      return 0;
    }
  };
  const incrementTries = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTries = getCurrentTries();

    localStorage.setItem(
      "triesData",
      JSON.stringify({ date: currentDate, tries: currentTries + 1 })
    );
  };
  return (
    <section id="try_photo">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>
              {t(`try_photo_title_1`)}
              <span className="c">{t(`try_photo_title_2`)}</span>
              {t(`try_photo_title_3`)}
            </span>
          </div>
          <p className="hdg_ttl_sub">{t(`try_photo_subtitle`)}</p>
        </h2>

        <div className="wrap">
          <div className="left">
            {mode === "loading" ? (
              <>
                <img src={beforeImg} alt="" />
                <img
                  className="process_loading"
                  src="./css/nivo-lightbox/loading.gif"
                  alt="Loading..."
                />
              </>
            ) : (
              <ReactCompareImage
                className="compare_image"
                leftImage={beforeImg}
                rightImage={afterImg}
              />
            )}
          </div>
          <div className="right">
            <div className="txt01">
              <span>{t(`try_photo_des`)}</span>
            </div>
            {/* <div className="photoHere">
              <span>{t(`try_photo_note_1`)}</span>
            </div> */}

            <div className="codeWrap">
              <Dropzone
                disabled={
                  mode === "loading" || getCurrentTries() >= PHOTO_MAX_TIME_TRY
                }
                ref={dropzoneRef}
                accept={{ "image/*": [".jpeg", ".png"] }}
                minSize={PHOTO_MIN_SIZE}
                maxSize={PHOTO_MAX_SIZE}
                maxFiles={1}
                onDrop={(acceptedFiles, fileRejections) => {
                  // Handle the rejected files
                  if (fileRejections && fileRejections.length > 0) {
                    fileRejections.forEach(({ file, errors }) => {
                      errors.forEach((e) => {
                        toast.error(t(e.code));
                      });
                    });
                    return;
                  }
                  // Handle the accepted files
                  processPhoto(acceptedFiles[0]);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="drag">
                        <span>
                          {mode === "loading"
                            ? t("try_photo_mes_loading")
                            : getCurrentTries() >= PHOTO_MAX_TIME_TRY
                            ? t("try_photo_mes_max_try")
                            : t("try_photo_mes_drop")}
                        </span>
                        {getCurrentTries() < PHOTO_MAX_TIME_TRY &&
                          mode !== "loading" && (
                            <span className="sub_drag">
                              {t("try_photo_mes_drop_sub")}
                            </span>
                          )}
                      </div>
                      {mode !== "loading" &&
                        getCurrentTries() < PHOTO_MAX_TIME_TRY && (
                          <div id="folder">
                            <img src={folderIco} alt="" />
                          </div>
                        )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div
              className="photoHere "
              style={{ textAlign: "left", color: "red" }}
            >
              <span>{t(`try_photo_note_2`)}</span>
            </div>
            <div id="btnProcess">{renderAction()}</div>
            <div className="forClick d-flex justify-content-center">
              <span>☟</span>
              <span>
                {t(`try_photo_mes_quick_try`)
                  .split("\n")
                  .map((line, ind) => (
                    <React.Fragment key={ind}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}{" "}
              </span>
              <span>☟</span>
            </div>
            <div className="photoItems">
              <div className="item">
                {exampleData.map((item, index) => {
                  return (
                    <div
                      className="i"
                      key={index}
                      onClick={() => processExamplePhoto(item)}
                    >
                      <img src={item.input} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
